import React, { createContext, useReducer } from "react";
import imgModalReducer from "../reducer/imgModalReducer";

export const SliderIndexStateContext = createContext();
export const SliderIndexDispatchContext = createContext()

const SliderIndexProvider = ({ children }) => {

    const initialState = {
        count: 0,
        length: 0
    }

    const [state, dispatch] = useReducer(imgModalReducer, initialState)

    return (
        <SliderIndexStateContext.Provider value={{state}}>
            <SliderIndexDispatchContext.Provider value={{dispatch}}>
                {children}
            </SliderIndexDispatchContext.Provider>
        </SliderIndexStateContext.Provider>
    )
};

export { SliderIndexProvider };
