

const actionTypes = {
    increment: "INCREMENT",
    decrement: "DECREMENT",
    onSelect: "ONSELECT",
    setLength: "SETLENGTH"
}

export const { increment, decrement, onSelect, setLength } = actionTypes;
