import React, { createContext, useMemo } from "react";
import { useOpen } from "../hooks/useOpen";

export const ContactModalStateContext = createContext();
export const ContactModalDispatchContext = createContext();

const ContactModalProvider = ({ children }) => {
    const { isOpen, setIsOpen, handleIsOpen, handleIsOpenModal } = useOpen(false)

    return (
        <ContactModalStateContext.Provider value={{ isOpen }}>
            <ContactModalDispatchContext.Provider value={{ setIsOpen, handleIsOpen, handleIsOpenModal }}>
                {children}
            </ContactModalDispatchContext.Provider>
        </ContactModalStateContext.Provider>
    )
};

export { ContactModalProvider };
