import React from "react";
import { ImageModalProvider } from "./src/provider/ImageModalProvider";
import { ContactModalProvider } from "./src/provider/ContactModalProvider";
import { SliderIndexProvider } from "./src/provider/SliderIndexProvider";

export const wrapRootElement = ({ element }) => (
    <SliderIndexProvider>
            <ContactModalProvider>
                <ImageModalProvider>
                    {element}
                </ImageModalProvider>
            </ContactModalProvider>
    </SliderIndexProvider>
);;